const groupConfig = () => [
  {
    navigation: {
      navItems: [
        {
          title: "Analytics",
          exact: true,
          disabled: false,
          to: "/intel",
          icon: "mdi-view-dashboard",
        },
        {
          title: "Reports",
          exact: true,
          disabled: false,
          to: "/intel/reports",
          icon: "mdi-file-chart",
        },
        {
          title: "Advisors",
          exact: true,
          disabled: false,
          to: { name: "List", query: { level: "advisor" } },
          icon: "mdi-account-group",
        },
        {
          title: "Leaderboard",
          exact: true,
          disabled: false,
          to: "/intel/leaderboard",
          icon: "mdi-scoreboard",
        },
        {
          title: "Calendar",
          exact: true,
          disabled: false,
          to: "/intel/calendar",
          icon: "mdi-calendar",
        },
        // {
        //   title: "Products",
        //   exact: true,
        //   disabled: false,
        //   to: "/intel/products",
        //   icon: "mdi-widgets",
        // },
        {
          title: "Survey Presenter",
          exact: true,
          disabled: false,
          to: "/intel/presenter",
          icon: "mdi-presentation",
        },
      ],
      navItems2: [
        {
          title: "New Advisor",
          exact: true,
          disabled: false,
          clickAction: "showProspectInviteDialog",
          icon: "mdi-email",
        },
      ],
    },
  },
];
export default groupConfig;
